// This is a generated file. Run "npm -w @trello/graphql run generate-static-resolver-info" if it needs to be updated.
export const resolverMap = {
  Query: {
    action: true,
    accessRequest: true,
    accessRequestVerification: true,
    announcements: true,
    archivedCardsForBoard: true,
    archivedCardSearch: true,
    board: true,
    boardActions: true,
    boards: true,
    boardsSearch: true,
    card: true,
    cards: true,
    cardActions: true,
    checklist: true,
    checklists: true,
    defaultOrganization: true,
    getWorkspacesForMember: true,
    invitationSecret: true,
    list: true,
    lists: true,
    emailProvider: true,
    member: true,
    members: true,
    memberCards: true,
    memberIdFromUsername: true,
    memberSearch: true,
    notificationGroups: true,
    notifications: true,
    notificationsCount: true,
    notificationChannelSettings: true,
    organization: true,
    organizations: true,
    organizationBoards: true,
    organizationMemberCards: true,
    organizationView: true,
    organizationViews: true,
    organizationInviteSecret: true,
    workspaceGuests: true,
    label: true,
    labels: true,
    enterprise: true,
    enterprises: true,
    publicPlugins: true,
    newSubscriptionListPriceQuotes: true,
    newSubscriptionPriceQuotes: true,
    upgradePriceQuotes: true,
    renewalPriceQuotes: true,
    memberStatements: true,
    organizationStatements: true,
    addMembersPriceQuotes: true,
    freeToAddMembers: true,
    search: true,
    plugin: true,
    plugins: true,
    pluginCategories: true,
    customField: true,
    qrCode: true,
    unsplashPhotos: true,
    templateCategories: true,
    templateLanguages: true,
    templateGallery: true,
    selfServeExpansionEstimate: true,
    pluginApplication: true,
    seatAutomationHistory: true,
    seatAutomationBlocklistMembers: true,
    fetchSeatAutomationPreview: true,
    fetchSeatAutomationExport: true,
    seatAutomationNextRunDate: true,
    getOrder: true,
  },
  Mutation: {
    addBoard: true,
    addAttachmentToCard: true,
    addCardComment: true,
    deleteCardComment: true,
    updateCardComment: true,
    deleteOrganization: true,
    addButlerButton: true,
    addChecklist: true,
    addOneTimeMessagesDismissed: true,
    addMessageDismissed: true,
    createOrganization: true,
    copyBoardToOrg: true,
    updateBoardOrg: true,
    updateBoardVisibility: true,
    updateBoardCardCoversPref: true,
    updateBoardCommentsPref: true,
    updateBoardHideVotesPref: true,
    updateBoardInvitationsPref: true,
    updateBoardSelfJoinPref: true,
    updateBoardSwitcherViewsPref: true,
    updateBoardTemplatePref: true,
    updateBoardVotingPref: true,
    dismissAnnouncement: true,
    deleteChecklist: true,
    updateChecklistName: true,
    updateChecklistPos: true,
    addCheckItem: true,
    deleteCheckItem: true,
    addCampaign: true,
    updateBoard: true,
    updateBoardMyPrefs: true,
    updateCampaign: true,
    updateCheckItem: true,
    convertCheckItemToCard: true,
    addBoardStar: true,
    removeBoardStar: true,
    updateBoardStar: true,
    addSavedSearch: true,
    deleteSavedSearch: true,
    deactivateEnterpriseMember: true,
    grantEnterpriseLicense: true,
    assignMemberEnterpriseAdmin: true,
    reactivateMember: true,
    removeEnterpriseMember: true,
    updateOrganization: true,
    updateOrganizationPermissionLevel: true,
    updateOrganizationOrgInviteRestrict: true,
    removeOrganizationOrgInviteRestrictDomain: true,
    updateOrganizationAtlassianIntelligenceEnabled: true,
    updateOrganizationPublicBoardVisibilityRestrict: true,
    updateOrganizationEnterpriseBoardVisibilityRestrict: true,
    updateOrganizationOrgBoardVisibilityRestrict: true,
    updateOrganizationPrivateBoardVisibilityRestrict: true,
    updateOrganizationPublicBoardDeleteRestrict: true,
    updateOrganizationEnterpriseBoardDeleteRestrict: true,
    updateOrganizationOrgBoardDeleteRestrict: true,
    updateOrganizationPrivateBoardDeleteRestrict: true,
    updateOrganizationBoardInvitationRestrict: true,
    updateOrganizationSlackTeamInvitationRestriction: true,
    updateOrganizationSlackTeamLinkRestriction: true,
    unlinkSlackTeam: true,
    enableMemberProfileSync: true,
    unblockMemberProfileSync: true,
    deleteOneTimeMessagesDismissed: true,
    createPlugin: true,
    deletePlugin: true,
    enablePlugin: true,
    updatePlugin: true,
    createPluginListing: true,
    updatePluginListing: true,
    deleteSharedPluginData: true,
    deletePluginListing: true,
    addPluginCollaborator: true,
    removePluginCollaborator: true,
    acceptDeveloperTerms: true,
    inviteMemberToJira: true,
    updateMemberProfile: true,
    updateCard: true,
    updateCardAttachment: true,
    updateCardCover: true,
    uploadCardCover: true,
    updateCardDueComplete: true,
    updateCardLabels: true,
    updateCardList: true,
    updateCardName: true,
    updateCardSubscription: true,
    updateCardPos: true,
    resendVerificationEmail: true,
    addMemberToOrg: true,
    updateWorkspaceMemberPermission: true,
    removeMemberFromWorkspace: true,
    deactivateMemberForWorkspace: true,
    reactivateMemberForWorkspace: true,
    archiveAllCards: true,
    unarchiveListMutation: true,
    archiveListMutation: true,
    createList: true,
    moveAllCards: true,
    updateListDatasourceLink: true,
    updateListDatasourceFilter: true,
    updateListColor: true,
    updateListPosition: true,
    updateListName: true,
    updateListSubscription: true,
    createCardTemplate: true,
    createCard: true,
    createCardFromFile: true,
    copyCard: true,
    archiveCard: true,
    unarchiveCard: true,
    deleteCard: true,
    changeCardDueDate: true,
    updateCardDates: true,
    updateCustomFieldItem: true,
    addStickerToCard: true,
    removeStickerFromCard: true,
    markAssociatedNotificationsRead: true,
    addMemberToCard: true,
    removeMemberFromCard: true,
    startBoardExport: true,
    startEnterpriseExport: true,
    claimOrganization: true,
    declineOrganizations: true,
    startOrganizationExport: true,
    createCustomField: true,
    deleteCustomField: true,
    updateCustomField: true,
    deleteCustomFieldOption: true,
    addCustomFieldOption: true,
    updateCustomFieldOption: true,
    linkEnterpriseWithAtlassianOrganization: true,
    preAuthorizeWorkspaceCreditCard: true,
    startWorkspacePaidSubscription: true,
    extendTrialPaidSubscription: true,
    updateOrganizationCreditCard: true,
    updateOrganizationPaidProduct: true,
    updateOrganizationBillingContactDetails: true,
    cancelWorkspacePaidAccount: true,
    updateOrganizationBillingInvoiceDetails: true,
    redeemPromoCode: true,
    uploadOrganizationImage: true,
    deleteOrganizationLogo: true,
    addFreeTrial: true,
    toggleAutoUpgrade: true,
    deleteManagedMemberTokens: true,
    deleteAllManagedMemberTokens: true,
    updateEnterpriseApiTokenCreationPermission: true,
    createDashboardViewTile: true,
    updateDashboardViewTile: true,
    deleteDashboardViewTile: true,
    updateCardRole: true,
    createOrganizationView: true,
    updateOrganizationView: true,
    updateViewInOrganizationView: true,
    deleteOrganizationView: true,
    updateCalendarKey: true,
    updateEmailKey: true,
    updateEmailPosition: true,
    messageEmailKey: true,
    updateCalendarFeedEnabledPref: true,
    updateBackgroundPref: true,
    addMemberToBoard: true,
    removeMemberFromBoard: true,
    updateBoardMemberPermission: true,
    addTag: true,
    deleteTag: true,
    editTag: true,
    toggleTag: true,
    setNotificationsRead: true,
    setAllNotificationsRead: true,
    deleteButlerButton: true,
    sendAccessRequest: true,
    deleteAccessRequest: true,
    deleteBoard: true,
    updateHiddenPluginBoardButtonShortcuts: true,
    bulkDisableWorkspacePowerUp: true,
    bulkEnableWorkspacePowerUp: true,
    createBoardInviteSecret: true,
    disableBoardInviteSecret: true,
    createSelfServeExpansion: true,
    updateDefaultWorkspace: true,
    createOrganizationInviteSecret: true,
    deleteOrganizationInviteSecret: true,
    createEnterpriseJoinRequest: true,
    deleteEnterpriseJoinRequest: true,
    addReaction: true,
    deleteReaction: true,
    createLabel: true,
    deleteLabel: true,
    updateLabel: true,
    updateMarketingOptIn: true,
    updateNotificationEmailFrequency: true,
    updateNotificationChannelSettings: true,
    createPluginApplication: true,
    updateApplication: true,
    dismissCompletedWorkspaceBatches: true,
    addMemberVoted: true,
    deleteMemberVoted: true,
    updateEnterprisePrefsSeatAutomation: true,
    updateEnterprisePrefsSeatAutomationBlockedMembers: true,
    createSeatAutomationExport: true,
    runSeatAutomation: true,
    createTransactionAccount: true,
    copyList: true,
    updateListLimit: true,
    sortList: true,
    createOrder: true,
    deleteCustomSticker: true,
    uploadCustomSticker: true,
    removeAttachmentFromCard: true,
    markCardAsViewed: true,
  },
  Board: {
    export: true,
    exports: true,
    stats: true,
    dashboardViewTile: true,
    history: true,
  },
  Board_History: {
    cardsPerList: true,
    cardsPerLabel: true,
    cardsPerMember: true,
    cardsPerDueDateStatus: true,
  },
  Card: {
    possibleCardRole: true,
  },
  Enterprise: {
    organizations: true,
    claimableOrganizations: true,
    defaultOrganization: true,
    transferrableData: true,
    managedMembersWithTokens: true,
    auditlog: true,
    export: true,
    memberships: true,
  },
  Member: {
    agreements: true,
    atlassianOrganizations: true,
  },
  Organization: {
    ownedPlugins: true,
    stats: true,
    newBillableGuests: true,
    cards: true,
    jiraEligibleMembers: true,
    slackAssociation: true,
  },
  Organization_Stats: {
    cards: true,
    labelNames: true,
  },
  Plugin: {
    collaborators: true,
  },
};
