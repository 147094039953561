import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { Analytics } from '@trello/atlassian-analytics';
import { atlassianSubscriptionUrl } from '@trello/config';
import { featureFlagClient } from '@trello/feature-flag-client';
import {
  internetConnectionState,
  verifyAndUpdateInternetHealthUntilHealthy,
} from '@trello/internet-connection-state';
import { monitor, monitorStatus } from '@trello/monitor';
import {
  calculateBackOffRange,
  getBackOffDelay,
} from '@trello/reconnect-back-off';
import {
  logConnectionInformation,
  safelyUpdateGraphqlWebsocketState,
} from '@trello/web-sockets';

export const retryWait = async (retries: number) => {
  safelyUpdateGraphqlWebsocketState('waiting_to_reconnect');
  // This is the logic reused from legacy WebSocket with a check verifyAndUpdateInternetHealthUntilHealthy
  // If the internet connection is healthy then we can retry, otherwise we do not need to retry
  // We subtract the time spent waiting to reconnect from the exponential backoff
  const retryDelayRange = calculateBackOffRange({
    status: monitor.getStatus(),
    attemptsCount: retries,
  });

  logConnectionInformation({
    source: 'graphqlWebsocketLink',
    eventName: 'Waiting until online',
  });
  const startedAt = Date.now();

  // Wait until the internet is healthy to try and connect
  await verifyAndUpdateInternetHealthUntilHealthy();
  logConnectionInformation({
    source: 'graphqlWebsocketLink',
    eventName: 'Done waiting until online',
  });
  // since we took some time to verify internet health, reduce the exponential backoff
  const msCheckingInternetHealth = Date.now() - startedAt;
  const retryDelay = getBackOffDelay({
    range: retryDelayRange,
    timeSpent: msCheckingInternetHealth,
  });

  let monitorUnsubscribe: (() => void) | undefined;
  // Wait the remaining delay. If we waited to reconnect longer than the original exponential backoff
  // then we will retry immediately.
  await new Promise((resolve) => {
    let timeoutId = setTimeout(resolve, retryDelay);

    logConnectionInformation({
      source: 'graphqlWebsocket',
      payload: {
        reconnectionAllowedIn: `${retryDelay}ms`,
        delayReason: 'Default delay',
        monitorStatus: monitor.getStatus(),
        retryDelayRange,
      },
      eventName: 'GraphQL WebSocket closed',
    });
    monitorUnsubscribe = monitorStatus.subscribe(
      (status) => {
        const newRetryDelayRange = calculateBackOffRange({
          status,
          attemptsCount: retries,
        });
        const msAlreadyWaited = Date.now() - startedAt;
        const newRetryDelay = getBackOffDelay({
          range: newRetryDelayRange,
          timeSpent: msAlreadyWaited,
        });

        const actualReconnectionDelay = Math.max(
          newRetryDelay - msAlreadyWaited,
          0,
        );

        logConnectionInformation({
          source: 'graphqlWebsocket',
          payload: {
            reconnectionAllowedIn: `${actualReconnectionDelay}ms`,
            reconnectDelayRange: newRetryDelayRange,
          },
          eventName: 'Bringing forward reconnection (user active)',
        });

        // Clear old timeout and set a new one with the updated delay
        clearTimeout(timeoutId);
        timeoutId = setTimeout(resolve, actualReconnectionDelay);
      },
      { onlyUpdateIfChanged: true },
    );
  });
  monitorUnsubscribe?.();
};

export const shouldRetry = () => {
  return featureFlagClient.get('gp.client-subscriptions', false);
};

export const createWsLink = () => {
  let traceId: string | null = null;
  const wsLink = new GraphQLWsLink(
    createClient({
      url: () => {
        if (!traceId) {
          if (featureFlagClient.get('gp.client-subscriptions', false)) {
            traceId = Analytics.startTask({
              taskName: 'create-session/socket/graphql',
              source: 'wsLink',
            });

            return `${atlassianSubscriptionUrl}?x-b3-traceid=${traceId}&x-b3-spanid=${Analytics.get64BitSpanId()}`;
          }
        }

        return atlassianSubscriptionUrl;
      },
      retryAttempts: 100,
      retryWait,
      shouldRetry,
      on: {
        connecting: () => {
          safelyUpdateGraphqlWebsocketState('connecting');
        },
        connected: () => {
          safelyUpdateGraphqlWebsocketState('connected');
          if (traceId) {
            Analytics.taskSucceeded({
              taskName: 'create-session/socket/graphql',
              source: 'wsLink',
              traceId,
            });

            traceId = null;
          }
        },
        closed: (event) => {
          /**
           * We're temporarily sending an operational event to see if we're receiving
           * any close codes back from the graphql-subscriptions service.
           */
          if (event instanceof CloseEvent) {
            Analytics.sendOperationalEvent({
              action: 'closed',
              actionSubject: 'socketConnection',
              source: 'network:socket',
              attributes: {
                code: event.code,
                reason: event.reason,
                wasClean: event.wasClean,
              },
            });
          }
        },
        error: () => {
          logConnectionInformation({
            source: 'graphqlWebsocketLink',
            eventName: 'graphql websocket errored',
          });
          safelyUpdateGraphqlWebsocketState('closed');
          if (traceId) {
            Analytics.taskFailed({
              taskName: 'create-session/socket/graphql',
              source: 'wsLink',
              traceId,
              error: new Error('Could not connect'),
            });

            traceId = null;
          }
        },
      },
    }),
  );

  /**
   * If the internet goes offline, then terminate the socket so that it reconnects.
   * This will end up waiting until we are online before reconnecting.
   */
  internetConnectionState.subscribe(
    (state) => {
      if (state === 'unhealthy') {
        wsLink.client.terminate();
      }
    },
    { onlyUpdateIfChanged: true },
  );

  return wsLink;
};

// eslint-disable-next-line @trello/no-module-logic
export const wsLink = createWsLink();
